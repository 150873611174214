import { Injectable, EventEmitter,inject,TemplateRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { BehaviorSubject,
  fromEvent,
  Observable,
  of,
  Subject,
  throwError, } from "rxjs";
import { NgbDatepickerModule, NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from '@angular/common/http';

import * as XLSX from 'xlsx';
import { constant } from "../shared/constant";

import { LoggedUserModel } from "../model/logged-user.model"

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public sidebar: EventEmitter<Boolean> = new EventEmitter();
  private userRoleSubject = new BehaviorSubject<string>('');
  public userRole$ = this.userRoleSubject.asObservable();

  constructor(
    public router: Router,
    private http: HttpClient,
    private _location: Location,
    // private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { 
    
  }

  private offcanvasService = inject(NgbOffcanvas);
	closeResult = '';

  // setUserRole(roleId: string): void {
  //   this.userRoleSubject.next(roleId);
  // }

  // navigateToDashboard(roleId: string): void {
  //   this.setUserRole(roleId);

  //   let navigationUrl = '';
  //   switch (roleId) {
  //     case '1':
  //       navigationUrl = '/admin/dashboard';
  //       break;
  //     case '2':
  //       navigationUrl = '/partner/dashboard';
  //       break;
  //     case '3':
  //       navigationUrl = '/account/dashboard';
  //       break;
  //     case '4':
  //       navigationUrl = '/location/dashboard';
  //       break;
  //     default:
  //       navigationUrl = '/login';
  //       break;
  //   }
  //   this.router.navigateByUrl(navigationUrl);
  // }

	openOffcanvas(content: TemplateRef<any>) {
    console.log(content)
		this.offcanvasService.open(content, { position: 'end', backdrop: 'static' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason:any) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}
  closeOffcanvas() {
		this.offcanvasService.dismiss();
	}

  private getDismissReason(reason: any): string {
		switch (reason) {
			case OffcanvasDismissReasons.ESC:
				return 'by pressing ESC';
			case OffcanvasDismissReasons.BACKDROP_CLICK:
				return 'by clicking on the backdrop';
			default:
				return `with: ${reason}`;
		}
	}

  toggleSidebar() {
    this.sidebar.emit();
  }

  isLogin(): any {
    const islogin = localStorage.getItem("islogin");
    return islogin;
  }

  /** Get Method */
  get(path: string, loader = true) {
    this.loading(loader);
    return this.http.get<any>(`${environment.apiUrl}${path}`).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }

  /** Get Method */
  getMultiple(
    path: string,
    first: string,
    second: string,
    options: any,
    loader = true
  ) {
    this.loading(loader);
    const params = new URLSearchParams();
    for (const key in options) {
      params.set(key, options[key]);
    }
    return this.http
      .get<any>(`${environment.apiUrl}${path}/${first}/${second}?${params}`)
      .pipe(
        map((res) => {
          this.loading(false);
          return res;
        })
      );
  }

  /** Get Method */
  getImage(path:any): Observable<Blob> {
    return this.http.get(path, { responseType: "blob" }).pipe((res) => {
      return res;
    });
  }

  /** Put Method */
  put(path:any, body:any, loader = true) {
    this.loading(loader);

    return this.http.put<any>(`${environment.apiUrl}${path}`, body).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }
  /** POST Method */
  post(path:any, body:any, loader = true) {
    this.loading(loader);
    return this.http.post<any>(`${environment.apiUrl}${path}`, body).pipe(
      map((res) => {
        this.loading(false);
        return res;
      }),
    );
  }

  postAndsave(path:any, body:any, loader = true, header:any) {
    this.loading(loader);
    return this.http
      .post<any>(`${environment.apiUrl}${path}`, body, header)
      .pipe(
        map((res) => {
          this.loading(false);
          return res;
        })
      );
  }

  getFile = (path:any, body:any, loader = true, header:any): Observable<any> => {
    this.loading(loader);

    return this.http
      .post<any>(`${environment.apiUrl}${path}`, body, header)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  /** DELETE Method */
  delete(path:any, body:any, loader = true) {
    this.loading(loader);
    return this.http.delete<any>(`${environment.apiUrl}${path}`, body).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }

  /** Query Params Method */
  queryParams(path: string, options: any, loader = true) {
    this.loading(loader);
    const params = new URLSearchParams();
    for (const key in options) {
      params.set(key, options[key]);
    }
    return this.http.get<any>(`${environment.apiUrl}${path}?${params}`).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }

  /** Query Params Method */
  getFileWithQueryParam(path: string, options: any, loader = true, header:any) {
    this.loading(loader);
    const params = new URLSearchParams();
    for (const key in options) {
      params.set(key, options[key]);
    }
    return this.http.get<any>(`${environment.apiUrl}${path}?${params}`, header).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }
  /** GET METHOD by ID*/
  getById(path:any, body:any, loader = true) {
    this.loading(loader);
    return this.http.get<any>(`${environment.apiUrl}${path}/${body}`).pipe(
      map((res) => {
        this.loading(false);
        return res;
      })
    );
  }
  /** Show Message  */
  showAlert(type:any, message:any, title = undefined) {  
    switch (type) {
      case "success":
        this.toastr.success(message, title);
        break;
      case "info":
        this.toastr.info(message);
        break;
      case "warn":
        this.toastr.warning(message);
        break;
      default:
        this.toastr.error(message);
        break;
    }
  }

  /** Login Storage GEt  */
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("LMSData") || "");
    return user ? true : false;
  }
  /** Loding Method Set and Get */
  loading(value = false) {
    this.isLoading.next(value);

    if (value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  /** Location back to previous URl */
  back() {
    this._location.back();
  }
  /** Clear local Storage */
  
   
  logout(path:any, loader = true) {
    this.loading(loader);
    const storedData = JSON.parse(localStorage.getItem("OBDIQ") || "{}");
    const headers = new HttpHeaders({
      'Authorization': storedData?.login_token ,  // Example of an Authorization header
      'Custom-Header': 'header-value'       // Example of a custom header
    });
    return this.http.get<any>(`${environment.apiUrl}${path}`, { headers }).pipe(
      map((res) => {
        this.loading(false);
        localStorage.clear();
        this.router.navigate(["/"]);
        return res;
      },
      (err: any) => {
        this.loading(false);
        this.showAlert("warn", err.message);
      })
    );
  }

  // logout() {
  //   this.delete('user/logout', {}).subscribe(
  //     async (res) => {
  //       this.showAlert("success", "Logged out successfully.");
  //       localStorage.clear();
  //       this.loggedUser = null;
  //       this.loggedUserSubject.next(null);
  //       this.router.navigate(["/"]);
  //     },
  //     (err) => {
  //       this.loading(false);
  //       this.showAlert("warn", err.error.message);
  //     }
  //   );

  //   this.router.navigate(["/"]);
  //   return of(true);
  // }

  /** Convert str to date In timeStamp*/
  convertDate(str:any) {
    return new Date(str).getTime();
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "PNG" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "JPG" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "PDF"
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateDeviceToken(token:any) {
    const user = JSON.parse(localStorage.getItem("LMSData") || "");
    var data = {
      id: user.id,
      device_token: token,
      login_token: user.login_token,
    };

    return this.http
      .put<any>(`${environment.apiUrl}/user/updateDeviceToken`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  formatDate(date:any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  getDateDiff(first:any, second:any) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }


  getNumberOfDays(start:any, end:any) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  // postthrid(path, body) {
  //   return this.http.post<any>(`${path}`, body).pipe(
  //     map((res) => {
  //       this.loading(false);
  //       return res;
  //     })
  //   );
  // }

  generateRandom(length = 32, alphanumeric = true) {
    let data = "",
      keys = "";
    if (alphanumeric) {
      keys = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    } else {
      keys = "0123456789";
    }
    for (let i = 0; i < length; i++) {
      data += keys.charAt(Math.floor(Math.random() * keys.length));
    }
    return data;
  }

  getDateFormat(currentDate:any) {
    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //Be careful! January is 0 not 1
    var year = currentDate.getFullYear();

    var dateString = date + "-" + (month + 1) + "-" + year;
    return dateString;
  }
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 9
    var years: any = [];
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  /* XIRR Calculation for Loan Repayment Start*/

  XIRR(cfs:any, dts:any, guess:any) {
    if (cfs.length != dts.length) throw new Error('Number of cash flows and dates should match');
    var positive, negative;
    Array.prototype.slice.call(cfs).forEach(function (value) {
      if (value > 0) positive = true;
      if (value < 0) negative = true;
    });

    if (!positive || !negative) throw new Error('XIRR requires at least one positive value and one negative value');
    guess = !!guess ? guess : 0;
    var limit = 100; //loop limit
    var guess_last;
    var durs: any = [];
    durs.push(0);

    //Create Array of durations from First date
    for (var i = 1; i < dts.length; i++) {
      durs.push(this.durYear(dts[0], dts[i]));
    }

    do {
      guess_last = guess;
      guess = guess_last - this.sumEq(cfs, durs, guess_last);
      limit--;
    } while (guess_last.toFixed(5) != guess.toFixed(5) && limit > 0);

    var xirr = guess_last.toFixed(5) != guess.toFixed(5) ? 0 : guess * 100;
    return Math.round(xirr * 100) / 100;
  }

  //Returns duration in years between two dates
  durYear(first:any, last:any) {
    return (Math.abs(last.getTime() - first.getTime()) / (1000 * 3600 * 24 * 365));
  }

  sumEq(cfs:any, durs:any, guess:any) {
    var sum_fx = 0;
    var sum_fdx = 0;
    for (var i = 0; i < cfs.length; i++) {
      sum_fx = sum_fx + (cfs[i] / Math.pow(1 + guess, durs[i]));
    }
    for (i = 0; i < cfs.length; i++) {
      sum_fdx = sum_fdx + (-cfs[i] * durs[i] * Math.pow(1 + guess, -1 - durs[i]));
    }
    return sum_fx / sum_fdx;
  }

  /* END --> XIRR Calculation for Loan Repayment */

  public exportAsExcelFile(json: any[], excelFileName: string, loanData: any): void {

    let mainData = [{ 'Loan Amount': loanData.sanctionedLoanAmount, 'Tenor (In Months)': loanData.tenor, 'Interest Only Tenor (In Months)': loanData.interestOnlyTenor, 'Interest Rate %': loanData.interestRate, 'Due Diligence Fee %': loanData.dueDiligenceFee, 'Processing Fee': loanData.processingFee, 'Audit Fee': loanData.auditFee, 'Other Fee': loanData.otherFee, 'EMI': loanData.EMI, 'Disbursed Amount': loanData.disbursedAmount, 'Disbursed Date': loanData.disbursedDate, 'EMI Due Date': loanData.EMIDueDate, 'EMI Reserve In Months': loanData.reversePayment }]
    let Heading = [['Date', 'PRINCIPAL PAYMENT', 'INTEREST PAYMENT', 'PRINCIPAL OUTSTANDING', 'TOTAL PAYMENT']];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_json(ws, mainData);

    this.create_gap_rows(ws, 3)
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A5' });
    XLSX.utils.sheet_add_json(ws, json, { origin: 'A6', skipHeader: true });


    //Starting in the second row to avoid overriding and skipping headers


    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'Term_Sheet_EMI.xlsx');
  }



  /* END --> XIRR Calculation for Loan Repayment */

  public exportTransactionsAsExcelFile(json: any[],account="",fipID:""): void {
   
    let Heading = [[ 'Date','Transaction Times','Transaction ID','Narration','Mode','Amount','Debit','Credit','Balance','Reference']];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
    XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });


    //Starting in the second row to avoid overriding and skipping headers


    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `Transactions_${fipID}_${account}.xlsx`);
  }

  private create_gap_rows(ws:any, nrows:any) {
    var ref = XLSX.utils.decode_range(ws["!ref"]);       // get original range
    ref.e.r += nrows;                                    // add to ending row
    ws["!ref"] = XLSX.utils.encode_range(ref);           // reassign row
  }


  matchName(panName:any,registeredName:any){   
    if(panName.replace(/\s/g, "").toLowerCase()===`${registeredName?.first_name}${registeredName?.middle_name}${registeredName?.last_name}`.toLowerCase()){
      return true;
    };
    return false;
  }

  calculateInterestIncome(processingFee:any, loanAmount:any, totalFinalPayment:any) {
    console.log("processingFee",processingFee )
    console.log("loanAmount",loanAmount )
    console.log("totalFinalPayment",totalFinalPayment )
   const feeIncome = Math.floor(loanAmount/ 100) * processingFee 
   console.log("feeIncome",feeIncome )
   const totalIncome= totalFinalPayment - 0.18 * feeIncome
   console.log("totalIncome",totalIncome )
   const interestIncome= totalIncome - feeIncome
   console.log("interestIncome",interestIncome )
   return {interestIncome :interestIncome, feeIncome:feeIncome}
  }
}

