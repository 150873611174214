<div class="modal-header">
    <!-- <h4 class="modal-title" style="text-align:center !important;">{{ title }}</h4> -->
      <button type="button" class="close top-right" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button"  cButton class="cta-btn cancel-btn" (click)="decline()">{{ btnCancelText }}</button>
      <button type="button"  cButton class="cta-btn submit-btn" (click)="accept()">{{ btnOkText }}</button>
    </div>